import Container from "./Container";
import { deviceSwitcherKey, validateUrl } from "@/utils/home";
import Link from "next/link";
import { useRouter } from "next/router";
import NextImage from "../NextImage";

export default function BannersGrid({ data, isMobile }) {
  const { locale } = useRouter();
  const { length } = (data?.value || [])?.filter(
    (bnr) => bnr[deviceSwitcherKey("active", isMobile)]
  );
  const gridClass =
    length === 1
      ? "grid-cols-1"
      : length === 2
      ? "max-[570px]:grid-cols-1 grid-cols-2 gap-3"
      : length === 3
      ? "max-[570px]:grid-cols-1 grid-cols-3"
      : length === 4
      ? "max-[570px]:grid-cols-1 grid-cols-4"
      : "";

  return (
    data?.value?.length > 0 && (
      <Container
        className={`grid ${gridClass} my-10 max-md:my-4 bg-white rounded-2xl`}
      >
        {data?.value?.map((bnr, i) => {
          const url = validateUrl(bnr?.url, bnr?.type);
          return (
            <Link
              key={bnr?.id + i}
              href={`/${url}${
                bnr?.type !== "url" && url !== ""
                  ? `?gtm=homepage&promotion_id=${bnr?.id}`
                  : ""
              }`}
              className="relative w-full my-auto overflow-hidden"
            >
              <NextImage
                priority={i < 4}
                src={bnr[deviceSwitcherKey("img", isMobile, locale)]}
                className="w-full h-auto rounded-2xl"
                width={Number(1440 / length) || 500}
                quality={100}
                height={400}
                alt={bnr?.id}
              />
            </Link>
          );
        })}
      </Container>
    )
  );
}
